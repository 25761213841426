<template>
  <div class="d-flex flex-column justify-center" style="width: 100%">
    <v-container v-if="contentTemplate !== ''" style="max-width: 1480px">
      <component
        :is="{
          name: 'dynamic-content',
          template: this.contentTemplate,
        }"
      />
    </v-container>
    <foo-ter />
  </div>
</template>

<script>
import template from "../data/services.json";

export default {
  data() {
    return {
      contentTemplate: template.template.content,
      // contentTemplate: "",
    };
  },
    metaInfo() {
    return {
      meta: [
        {
          vmid: "description",
          name: "description",
          content: "Loviisan Aitta Palvelut",
        },
      ],
    };
  },
  created: function () {
    // this.BBShopApi("services").then((res) => {
    //   this.contentTemplate = res.data.template.content;
    //   if (res.data.template.data) {
    //     this.$store.commit("updateCMSData", res.data.template.data);
    //   }
    // });
    if (template.template.data) {
    this.$store.commit("updateCMSData", template.template.data);
    }
  },
};
</script>

<style>
</style>